'use client';

export default function GlobalError({ error }: { error: Error & { digest?: string } }) {
	return (
		<html>
			<body>
				<div>
					<h2>Something went wrong</h2>
					{error.message}
				</div>
			</body>
		</html>
	);
}
